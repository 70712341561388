<template>
  <el-dialog title="设置标签"
             width="50%"
             :close-on-click-modal='false'
             :modal-append-to-body="false"
             :visible.sync="dialogVisible">
    <el-form :model="form"
             :rules="rules"
             label-position="top"
             ref="form">
      <el-form-item label="标签名称"
                    prop="name">
        <el-input type="text"
                  placeholder="请输入标签名称"
                  v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="排序"
                    prop="index">
        <el-input type="text"
                  placeholder="请输入库存，越小越靠前"
                  v-model.number="form.index"></el-input>
      </el-form-item>
      <el-form-item label="是否启用">
        <el-switch v-model="form.status"></el-switch>
      </el-form-item>
    </el-form>
    <span slot="footer"
          class="dialog-footer">
      <el-button type="primary"
                 @click="submitForm('form')">立即{{form.id?'修改':'创建'}}</el-button>
    </span>
  </el-dialog>

</template>

<script>
import { setTab } from '@/api/equity'
export default {
  name: 'SetTab',
  data () {
    return {
      dialogVisible: false,
      form: {
        id: '',
        name: '',
        status: true,
        index: 0
      },
      rules: {
        index: [
          { required: true, message: '请输入排序', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入标签', trigger: 'blur' }
        ],
        started_at: [
          { required: true, message: '请选择起止时间', trigger: 'change' }
        ]
      }
    }
  },
  components: {
  },
  methods: {
    showForm (form) {
      this.dialogVisible = true
      this.$nextTick(() => {
        if (form) {
          const formData = Object.assign({}, form)
          this.form = formData
        } else {
          this.form = {
            id: '',
            name: '',
            status: true,
            index: 0
          }
        }
      })
    },
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await setTab(this.form)
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: `${this.form.id ? '修改' : '创建'}成功`,
              type: 'success'
            })
            this.dialogVisible = false
            this.$emit('on-success')
          } else {
            this.$message.error(res.meta.msg)
          }
        } else {
          this.$message.error('表单信息填写有误, 请检查')
        }
      })
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
</style>

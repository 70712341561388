<template>
  <div class="container">
    <table-page dataName="label"
                ref="table"
                :search="search"
                :request="getTabList">
      <template slot="button">
        <el-button type="primary"
                   v-permission="['ADMIN']"
                   @click="setTab()">新建标签</el-button>
      </template>

      <el-table-column prop="id"
                       show-overflow-tooltip
                       label="ID">
      </el-table-column>
      <el-table-column prop="name"
                       show-overflow-tooltip
                       label="标签名称">
      </el-table-column>
      <el-table-column prop="index"
                       show-overflow-tooltip
                       label="排序">
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <span v-if="scope.row.status">
            <el-badge is-dot
                      type="success"
                      class="mr-15"></el-badge>启用
          </span>
          <span v-else>
            <el-badge is-dot
                      type="danger"
                      class="mr-15"></el-badge>禁用
          </span>
        </template>
      </el-table-column>
      <el-table-column label="操作"
                       width="200px">
        <template slot-scope="scope">
          <el-button type="text"
                     @click="setTab(scope)">编辑</el-button>
          <el-button type="text"
                     @click="delTab(scope.row.id)">删除</el-button>

        </template>
      </el-table-column>
    </table-page>
    <SetTab ref="setTab"
            @on-success="$refs.table.fetch()" />
  </div>
</template>

<script>
import TablePage from '@/components/TablePage'
import SetTab from './components/SetTab'
import { getTabList, delTab } from '@/api/equity'
export default {
  name: 'Tabs',
  data () {
    return {
      getTabList,
      search: [{
        type: 'input',
        name: '标签名称',
        placeholder: '请输入标签名称',
        key: 'name',
        value: ''
      }],
      searchParams: {}
    }
  },
  components: {
    TablePage,
    SetTab
  },
  methods: {
    setTab (scope) {
      this.$refs.setTab.showForm(scope ? scope.row : null)
    },
    delTab (id) {
      this.$confirm('确认删除', {
        type: 'warning'
      }).then(async () => {
        const res = await delTab({
          id: id
        })
        if (res.meta.code === 0) {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          })
          this.$refs.table.fetch()
        }
      })
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
</style>
